import React, { useMemo, useState, useEffect } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import CheckoutFormBid from "./CheckoutFormBid";
import "./App.css";

interface PaymentProps {
  amountBI: number;
  description: string;
  owner: string;
  currency: string;
  buyer: string;
  orderPass: any[];
}


const stripePromise = loadStripe("pk_test_51OUV28E9rT114Xkd3hicFE8W5g6GlsgQJz1pvmpDAVF9KBWB1377rgydEUnzdFS9w1RGRidsxHaSAz5cBpmNbkh200eDgslAYD");

const PaymentBid: React.FC<PaymentProps> = ({ amountBI, description, owner, currency, buyer, orderPass }) => {
const [clientSecret, setClientSecret] = useState("");

const [indexLanguage, setIndexLanguage] = useState('');

const currentHostname = window.location.href;
const stagingKey = process.env.REACT_APP_KEY_STAGING || 'staging2';
const linkUrl = currentHostname.includes(`${stagingKey}`) 
  ? `https://${stagingKey}.peaxel.me` 
  : 'https://peaxel.me';

useEffect(() => {
  // Get the language from localStorage when the component mounts
  const storedLanguage = localStorage.getItem('pxlLanguage') || 'en'; // Default to 'en' if not found
  setIndexLanguage(storedLanguage);
}, []); // Empty dependency array ensures this runs once on mount

useEffect(() => {
  // Define an async function inside the useEffect
  const fetchData = async () => {
    let amount_connect = 0;
    let connectID = '';
    let transfer_groupz='';
    let triage= 'activeConnect'; //status has a stripe connect and activated


    const metadataURL = `${linkUrl}/wp-json/custom/v1/get-metadata-by-metadata/?metadata_name_A=wallet_address&value_metadata_name_A=${owner}&metadata_name_B=stripe_connect_id`;
    const responseMetadata = await fetch(metadataURL);
    const metadata = await responseMetadata.json();
    const stripeConnectId = metadata.meta_value_B;


    if (stripeConnectId) {
      // Check if the Stripe account is active
      const statusURL = `${linkUrl}/wp-json/custom/v1/check-account-status/?account_id=${stripeConnectId}`;
   const responseStatus = await fetch(statusURL);
      const accountData = await responseStatus.json();

      if (accountData.transfers_capability_status === 'active') {

        connectID = stripeConnectId; // Removed const to not redeclare it
  
        amount_connect = parseFloat((parseFloat(amountBI.toString()) * 0.97).toFixed(0));
        transfer_groupz='2nd_Market';
     
        const serverURL = `${linkUrl}/wp-json/custom/v1`;

  
        fetch(`${serverURL}/create-payment-intent`, {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            items: [{ id: "Auction" }],
            amount: amountBI, // Assuming amountBI is available in bidOrder
            currency: currency, // Assuming currency is available in bidOrder
            application_fee: amount_connect,
            destination: connectID,
            description: owner.toString(),
          }),
        })
          .then((res) => res.json())
          .then((data) => {
            if (data.clientSecret) { // Make sure response includes clientSecret
              // Assuming setClientSecret updates state or props
              setClientSecret(data.clientSecret);
            }
          }).catch(error => console.error("Error creating payment intent:", error));
  
      


      } else {
        
        //console.log('STRIPE NOT ACTIVATED');
        const currentHostname = window.location.href;
    const stagingKey = process.env.REACT_APP_KEY_STAGING || 'staging2';
    const linkUrl = currentHostname.includes(`${stagingKey}`) 
      ? `https://${stagingKey}.peaxel.me` 
      : 'https://peaxel.me';
        
        const serverURL = `${linkUrl}/wp-json/custom/v1`;
  
        fetch(`${serverURL}/create-payment-intent-no-connect`, {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            items: [{ id: "Auction" }],
            amount: amountBI, // Assuming amountBI is available in bidOrder
            currency: currency, // Assuming currency is available in bidOrder
          }),
        })
          .then((res) => res.json())
          .then((data) => {
            if (data.clientSecret) { // Make sure response includes clientSecret
              // Assuming setClientSecret updates state or props
              setClientSecret(data.clientSecret);
            }
          }).catch(error => console.error("Error creating payment intent:", error));
  
      }

    
    
    } else {
      
      //console.log('ADMIN');
      const currentHostname = window.location.href;
    const stagingKey = process.env.REACT_APP_KEY_STAGING || 'staging2';
    const linkUrl = currentHostname.includes(`${stagingKey}`) 
      ? `https://${stagingKey}.peaxel.me` 
      : 'https://peaxel.me';
        
      const serverURL = `${linkUrl}/wp-json/custom/v1`;

      fetch(`${serverURL}/create-payment-intent-no-connect`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          items: [{ id: "Auction" }],
          amount: amountBI, // Assuming amountBI is available in bidOrder
          currency: currency, // Assuming currency is available in bidOrder
        }),
      })
        .then((res) => res.json())
        .then((data) => {
          if (data.clientSecret) { // Make sure response includes clientSecret
            // Assuming setClientSecret updates state or props
            setClientSecret(data.clientSecret);
          }
        }).catch(error => console.error("Error creating payment intent:", error));

    }

    
  };

  // Call the async function
  fetchData();
}, []); // Include dependencies


  //console.log('theAmountBid',amountBI);

  orderPass.forEach((offer: any) => {
    offer.client_secret_stripe = clientSecret;
  });

  //console.log('Pass',orderPass);

  const appearance = {
    theme: "night",
  };

  const options = {
    clientSecret,
    appearance: {
      labels: "floating",
      theme: "flat",
      variables: {
        colorBackground: "#101010",
        colorText: "#818181",
        colorDanger: "#d51bdf",
      },
      rules: {
        ".Input": {
          border: "0.5px solid #515151",
        },
      },
    },
  } as const;

  return (
    <div className="Payment">
      {clientSecret && (
        <Elements options={options} stripe={stripePromise}>
          <CheckoutFormBid orderPass2={orderPass} />
        </Elements>
      )}
    </div>
  );
};

export default PaymentBid;

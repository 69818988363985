import React, { useEffect, useState, useRef, lazy, Suspense, useMemo } from 'react';
import './App.css'; // Import your CSS file for the modal styles
import axios from 'axios'; // Import axios for API requests
import { isMobile, Link, ERC721TokenType} from '@imtbl/imx-sdk';
import Payment from './Payment';
import PaymentBid from './PaymentBid';
import {AssetResponse, TalentCard, Order, Asset} from './types';
import { handleRefresh } from './RefreshUtils'; 
import { openPageViewModal } from './PageViewModalUtils';
import { throttle } from 'lodash';
import { useTranslation } from 'react-i18next';
import './i18n'; // Import the i18n configuration

type IframePositionKeys = 'left';

const ExperienceDisplayLazy = lazy(() => import('./experienceDisplay'));
const CustomTooltipLazy = lazy(() => import('./toolTip'));
const LastSalesLazy = lazy(() => import('./lastSales'));
const NotificationLazy = lazy(() => import('./notifications'));

type IframePositionOptions = {
  [key in IframePositionKeys]?: string;
};

type ConfigurableIframeOptions = null | {
  className?: string;
  containerElement?: HTMLElement;
  protectAgainstGlobalStyleBleed?: boolean;
  position?: IframePositionOptions;
};

interface OrderModalProps {
  order: Order;
  onClose: () => void;
  exchangeRates: { USD: number; EUR: number } | null;
  currency: 'EUR' | 'USD';
  locationPath: string;
  activeSection: string;
}


// Function to format the expiration timestamp
const formatExpirationTimestamp = (timestamp: any) => {
  const date = new Date(timestamp);

  // Check if the year is the current year
  const isCurrentYear = date.getFullYear() === new Date().getFullYear();

  const year = isCurrentYear ? '' : date.getFullYear();
  const month = date.toLocaleString('default', { month: 'short' });
  const day = date.getDate();
  const hours = date.getHours();
  const minutes = date.getMinutes();
  const amOrPm = hours >= 12 ? 'PM' : 'AM';

  // Convert hours from 24-hour format to 12-hour format
  const formattedHours = hours % 12 || 12;

  // Combine the formatted parts into the desired format
  const formattedDate = `${year} ${month} ${day}, ${formattedHours}:${minutes.toString().padStart(2, '0')} ${amOrPm}`;

  return formattedDate;
};


  

  const OrderModal: React.FC<OrderModalProps> = ({ order, onClose, exchangeRates, currency, locationPath, activeSection }) => {
 
    console.log('order',order);
    const { t } = useTranslation();
    const isMobileConst = window.innerWidth <= 768;

    const [orderInfo, setOrderInfo] = useState<any>(null); // Store the fetched order information

    const pxlBalance = Number(localStorage.getItem('pxlBalance')) || 0; // Retrieve and convert pxlBalance to a number

    const [isMakingOffer, setIsMakingOffer] = useState(false);
    const [offerAmount, setOfferAmount] = useState(''); // State for the offer amount input
    const [showCursor, setShowCursor] = useState(false);
    const [isOfferSectionVisible, setIsOfferSectionVisible] = useState(true);
    const [divIdToReload, setDivIdToReload] = useState<string | null>(null);
    const [divIdToReloadMajor, setDivIdToReloadMajor] = useState<string | null>(null);
    const [activity, setActivity] = useState<string | null>(null);
    const [tokenImage, setTokenImage] = useState<string | null>(null);
    const [isSubscriber, setIsSubscriber] = useState(false);

    const [walletAddress, setWalletAddress] = useState(localStorage.getItem('walletAddress'));
    

    const [offerAmountInETH, setOfferAmountInETH] = useState<number | null>(null);
    const [isOfferButtonVisible, setIsOfferButtonVisible] = useState(false); // State to control the visibility of the "Make an Offer" button
    const [isPaymentSectionVisible, setIsPaymentSectionVisible] = useState(false);
    const [isBuyOutButtonVisible, setIsBuyOutButtonVisible] = useState(true);


    const [notification, setNotification] = useState<{ message: string; backgroundColor?: string } | null>(null);

    const [maximumBid, setMaximumBid] = useState<number>(0);
    const [desiredSale, setDesiredSale] = useState<number>(0);

    const [acceptedCardPayment, setAcceptedCardPayment] = useState(false);
   const [acceptedETHPayment, setAcceptedETHPayment] = useState(false);

   const currentHostname = window.location.href;
   const stagingKey = process.env.REACT_APP_KEY_STAGING || 'staging2';
   const linkUrl = currentHostname.includes(`${stagingKey}`) 
     ? `https://${stagingKey}.peaxel.me` 
     : 'https://peaxel.me';

   const [valueUSD, setValueUSD] = useState<number | undefined>();
   const [valueEUR, setValueEUR] = useState<number | undefined>();
   const [valueETH, setValueETH] = useState<number | undefined>();

   const [orderTalentCard, setOrderTalentCard] = useState<TalentCard | null>(null);

const [displayBid, setDisplayBid] = useState<number | null>(null);
const [showBuyOut, setShowBuyOut] = useState<boolean>(false);
const [buyOutValue, setBuyOutValue] = useState<number>(0);
const [buyOutValueETH, setBuyOutValueETH] = useState<number>(0);
const [nextAmount, setNextAmount] = useState<number>(0);  

   
    const [orderList, setOrderList] = useState<({
      offer_number: string;
      order_number: string;
      eth_value: any;
      buyer: string[] | string; // Update buyer type to allow for both array and string
      date_time_payment: any;
      client_secret_stripe: string;
      euro_value: any;
      dollar_value: any;
      eth_or_cashbid: any;
      refSales: any;
      walletuser: any;
      status: any;
      token_address: any;
      token_id: any;
      currency: any;
      owner:any;
      winner: any;
    } | null)[]>([]);

    interface ValueResponse {
      label: string;
      data: number;
    }

    
   
    const triggerNotificationWithMessage = (message: string, backgroundColor?: string) => {
      setNotification({ message, backgroundColor });
    };


  const closeNotification = () => {
    setNotification(null);
  };


    const togglePaymentSection = () => {
      setIsPaymentSectionVisible(true);
      setIsOfferSectionVisible(false);
      setIsOfferButtonVisible(true);
      setIsBuyOutButtonVisible(false);
    };

    useEffect(() => {
      
      setValueUSD(parseFloat(order.talentCard.USD_value));
      setValueEUR(parseFloat(order.talentCard.EUR_value));
      setValueETH(parseFloat(order.talentCard.ETH_value));

      if (walletAddress === order.user){
        setIsPaymentSectionVisible(false);
        setIsOfferSectionVisible(false);
      }
    }, []);


    useEffect(() => {
     
      if (locationPath.includes('market')){
        setIsPaymentSectionVisible(false);
        setIsOfferSectionVisible(false);
        setIsOfferButtonVisible(true);
      }

      if (activeSection.includes('offersReceived')){
        setIsPaymentSectionVisible(false);
        setIsOfferSectionVisible(false);
        setIsOfferButtonVisible(false);
      }

    }, [order]);

    useEffect(() => {
      const fetchMetadata = async () => {
        try {
          setActivity(order.talentCard.card_force || 'Not available');
          setTokenImage(order.talentCard.token_new_url || null);
        } catch (error) {
          console.error('Error fetching metadata: ', error);
          setActivity('Error fetching metadata.');
          setTokenImage('');
        }
      };
  
      fetchMetadata();
    }, [order]); // Re-fetch if token_id or token_address changes
  
    //useEffect(() => {
      //const handleScroll = throttle(() => {
        // Your scroll-related logic here
    //  }, 100); // Fires every 100ms
    
     // window.addEventListener('scroll', handleScroll);
     // return () => {
     //   window.removeEventListener('scroll', handleScroll);
     // };
    //}, []);

    // Initialize defaultOfferAmount with the calculated default value
    const [defaultOfferAmount, setDefaultOfferAmount] = useState('');

    const offerInputRef = useRef<HTMLInputElement | null>(null);

    const handleMakeOfferClick = () => {
      setIsOfferSectionVisible(true);
      setIsPaymentSectionVisible(false); 
      
      setTimeout(() => {
        if (offerInputRef.current) {
          const input = offerInputRef.current;
          input.focus();
  
          // Set the caret position to the end of the input value
          const valueLength = input.value.length;
          input.setSelectionRange(valueLength, valueLength);
        }
      }, 0);
  
      // Hide the "Make an Offer" button after it's clicked
      setIsOfferButtonVisible(false);
      setIsBuyOutButtonVisible(true);
    };

    const handleOfferInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      const inputAmount = e.target.value;
      setOfferAmount(inputAmount);

      
    // Calculate the ETH value based on the exchange rate and update it
  if (!isNaN(parseFloat(inputAmount)) && parseFloat(inputAmount) >= 0 && exchangeRates) {
    const rate = currency === 'USD' ? exchangeRates.USD : exchangeRates.EUR;
    const amountInETH = parseFloat((parseFloat(inputAmount) / rate).toFixed(6)); // Parse as a number
    setOfferAmountInETH(amountInETH);
  } else {
    setOfferAmountInETH(null); // Reset to null if the input is invalid
  }

  if (
    !isNaN(parseFloat(inputAmount)) &&
    parseFloat(inputAmount) >= 0 &&
    inputAmount !== offerAmount
  ) {
    const namediv = 'CreditCardBid'
    setDivIdToReload(namediv);
  }
    };

  
    const fetchPaymentMethods = async (order:any) => {
      const refSALE = `${order.order_id}_SALE`;
      try {
        const response = await fetch(`${linkUrl}/wp-json/custom/v1/sales-request-registry?columnA=ref&valueA=${refSALE}&columnB=eth_or_cash`);

        if (response.ok) {
          const data = await response.json();
    
          if (data.toString().includes('FIA')) {
            setAcceptedCardPayment(true);
          }
    
          if (data.toString().includes('ETH')) {
            setAcceptedETHPayment(true);
          }
        } else {
          // Handle error if needed
          console.error('Failed to fetch payment methods:', response.status);
        }
      } catch (error) {
        // Handle fetch error if needed
        console.error('Error fetching payment methods:', error);
      }
    };

    fetchPaymentMethods(order);
  
    async function cleanOrders(orderID: any) {
      try {
        // Fetch data based on orderID
        const selectionResponse = await fetch(`${linkUrl}/wp-json/custom/v1/sales-request-registry?columnA=order_number&valueA=${orderID}&columnB=ref`);
        const selection = await selectionResponse.json();
    
        // Loop through each refx in the selection
        for (const refx of selection) {
          // Convert refx to a string
          const ref = `${refx}`;
         
    
          // Fetch data based on ref
          const statusRefResponse = await fetch(`${linkUrl}/wp-json/custom/v1/sales-request-registry?columnA=ref&valueA=${ref}&columnB=status`);
          const statusRef = String(await statusRefResponse.json());

       // Check if status is "Pending" or "toTransfer"
          if (statusRef === "Pending" || statusRef === "toTransfer") {
            // Create clean order data with ref as a template literal
            const cleanOrder = [
              {
                ref: `${ref}`,
                status: 'Cancelled',
              }
            ];
            saveSalesData(cleanOrder);
            // Save clean order data
            // Note: Adjust the saving logic based on your requirements
     
          }
        }
    
        // End of the loop
      } catch (error) {
        console.error('Error processing orders:', error);
      }
    }
    
    

    // Define SalesRegistry function
    const saveSalesData = async (data: any) => {
      // Make the API request for each item in the data array
      for (const item of data) {
        try {
          const serverURL = `${linkUrl}/wp-json/custom/v1`;
    
          await fetch(`${serverURL}/sales-registry/`, {
            method: "POST",
            headers: {"Content-Type": "application/json"},
            body: JSON.stringify(item),
          });
    
          console.log('Data saved successfully');
        } catch (error) {
          console.error('Fetch error:', error);
        }
      }
    };


    async function cancelOrder(orderID:any) {
      // Initialize Link with your API URL
      const linkIframeOptions: ConfigurableIframeOptions = isMobile()
  ? null // On mobile, fallback to pop-up
  : { className: 'my-link-iframe' };

      let link = new Link('https://link.sandbox.x.immutable.com', linkIframeOptions);

      const cancelORDERlist = [
        {
          ref: `${orderID}_SALE`,
          status: 'Cancelled',
        },
        // Add more test data as needed
      ];

      try {
        // Call the cancel method with the orderId parameter
        const result = await link.cancel({
          orderId: order.order_id.toString()
        });
        // Print the result
      
        saveSalesData(cancelORDERlist);
        cleanOrders(orderID);
        triggerNotificationWithMessage(`Talent Token removed from Sale`, '#20AC61')
        setTimeout(onClose, 2500);
        handleRefresh();
    
        // You can do additional handling or update state based on the result if needed
      } catch (error) {
       
        triggerNotificationWithMessage(`Something went wrong. ${error}`, '#D31148');
        // You can handle errors or update state based on the error if needed
      }
    }

  const handleMakeOffer = async (offerData:any) => {


    const linkIframeOptions: ConfigurableIframeOptions = isMobile()
  ? null // On mobile, fallback to pop-up
  : { className: 'my-link-iframe' };


    let link = new Link('https://link.sandbox.x.immutable.com', linkIframeOptions);

    
      
      try {
  
        if (!isNaN(parseFloat(offerAmount)) && parseFloat(offerAmount) > 0) {
          if (exchangeRates) {

            const oneWeekInSeconds = 7 * 24 * 60 * 60 + 3600 ; // Number of seconds in a week
            const currentTimestamp = Math.floor(Date.now() / 1000); // Current Unix timestamp in seconds
            const expirationTimestamp = (currentTimestamp + oneWeekInSeconds).toString();

            const rate = currency === 'USD' ? exchangeRates.USD : exchangeRates.EUR;
            const amountInETH = ((parseFloat(offerAmount) / rate ).toFixed(10)).toString();
            const result = await link.makeOffer({
              amount: amountInETH,
              tokenId: order.sell.data.token_id,
              tokenAddress: order.sell.data.token_address,
             /* expirationTimestamp: expirationTimestamp,*/
            }); 

          
          const retour =(result as any).order_id;

          if (retour) {
    
            let amountInUSD = 0;
            let amountInEUR = 0;
        
            if (currency === 'USD') {
              amountInUSD = parseFloat(offerAmount);
              amountInEUR = parseFloat(offerAmount) / exchangeRates.USD * exchangeRates.EUR;
            } else if (currency === 'EUR') {
              amountInEUR = parseFloat(offerAmount);
              amountInUSD = parseFloat(offerAmount) / exchangeRates.EUR * exchangeRates.USD;
            }
        
            offerData.forEach((offer: any) => {
              offer.offer_number = retour;
              offer.eth_value = amountInETH;
              offer.dollar_value = amountInUSD;
              offer.euro_value = amountInEUR;
            });

            saveSalesData(offerData);
            console.log('orderData',offerData);
            setIsOfferSectionVisible(false);
            const namediv = 'saleModal'
            setDivIdToReloadMajor(namediv);
            triggerNotificationWithMessage(`Offer successfully submitted`, '#20AC61')
            handleRefresh();
           
          } else {
          triggerNotificationWithMessage(`Something went wrong.`,'#D31148')
          }
           
          } else {
          triggerNotificationWithMessage(`Something went wrong. Error: Exchange rates`,'#D31148')
          }
        
        } else {
        triggerNotificationWithMessage(`Something went wrong.`,'#D31148')
        }
        } catch (error) {
        console.error(error);
        triggerNotificationWithMessage(`Something went wrong. ${error}`,'#D31148')
        }
    };

  const handleMakeOfferBuyOut = async (offerData:any) => {

    const linkIframeOptions: ConfigurableIframeOptions = isMobile()
  ? null // On mobile, fallback to pop-up
  : { className: 'my-link-iframe' };


    let link = new Link('https://link.sandbox.x.immutable.com', linkIframeOptions);


      const completeSALE = [
        {
          ref: `${offerData?.order_number}_SALE`,
          status: 'inProgress',
        },
    ];
      
      try {
  
        if (!isNaN(buyOutValue) && buyOutValue > 0) {
          if (exchangeRates) {

            const oneWeekInSeconds = 7 * 24 * 60 * 60 + 3600 ; // Number of seconds in a week
            const currentTimestamp = Math.floor(Date.now() / 1000); // Current Unix timestamp in seconds
            const expirationTimestamp = (currentTimestamp + oneWeekInSeconds).toString();

            const rate = currency === 'USD' ? exchangeRates.USD : exchangeRates.EUR;
            const amountInETH = ((buyOutValue / rate ).toFixed(10)).toString();
            const result = await link.makeOffer({
              amount: amountInETH,
              tokenId: order.sell.data.token_id,
              tokenAddress: order.sell.data.token_address,
             /* expirationTimestamp: expirationTimestamp,*/
            }); 

            
          const retour =(result as any).order_id;

          if (retour) {
            
            let amountInUSD = 0;
            let amountInEUR = 0;
        
            if (currency === 'USD') {
              amountInUSD = buyOutValue;
              amountInEUR = buyOutValue / exchangeRates.USD * exchangeRates.EUR;
            } else if (currency === 'EUR') {
              amountInEUR = buyOutValue;
              amountInUSD = buyOutValue / exchangeRates.EUR * exchangeRates.USD;
            }
        
            offerData.forEach((offer: any) => {
              offer.offer_number = retour;
              offer.eth_value = amountInETH;
              offer.dollar_value = amountInUSD;
              offer.euro_value = amountInEUR;
            });

            saveSalesData(offerData);
            saveSalesData(completeSALE);
            console.log('orderData',offerData);
            setIsPaymentSectionVisible(false);
              const namediv = 'saleModal'
            setDivIdToReloadMajor(namediv);
            triggerNotificationWithMessage(`BuyOut successfully submitted`, '#20AC61')
            handleRefresh();
            setTimeout(onClose, 2500);
          
          } else {
          triggerNotificationWithMessage(`Something went wrong.`,'#D31148')
          }
           
          } else {
          triggerNotificationWithMessage(`Something went wrong. Error: Exchange rates`,'#D31148')
          }
        
        } else {
        triggerNotificationWithMessage(`Something went wrong.`,'#D31148')
        }
        } catch (error) {
        console.error(error);
        triggerNotificationWithMessage(`Something went wrong. ${error}`,'#D31148')
        }
    };
   
  async function acceptBid(orderBid: any) {
        const accept_ETH = [
          {
            ref: `${orderBid.refSales}`,
            date_time_validation: new Date().toISOString(),
            status: 'Completed',
          },
          // Add more test data as needed
        ];
  
        const completeSALE = [
          {
            ref: `${orderBid.order_number}_SALE`,
            date_time_validation: new Date().toISOString(),
            status: 'Completed',
          },
      ];
      
        try {
          const linkIframeOptions: ConfigurableIframeOptions = isMobile()
  ? null // On mobile, fallback to pop-up
  : { className: 'my-link-iframe' };
    
          let link = new Link('https://link.sandbox.x.immutable.com', linkIframeOptions);
          // Call the acceptOffer method with the orderId
          await link.acceptOffer({
            orderId: orderBid.offer_number,
          });
      
          
          // Call saveSalesData to save sales data
          saveSalesData(accept_ETH);
          saveSalesData(completeSALE);
          cleanOrders(orderBid.order_number);
         
          setIsOfferSectionVisible(false);
          const namediv = 'saleModal'
          setDivIdToReloadMajor(namediv);
          triggerNotificationWithMessage(`${t('congrats')}. ${t('saleSuccessfullyCompleted')}`, '#20AC61');
          handleRefresh();
          setTimeout(onClose, 2500);
          
        } catch (error) {
          // Handle errors, you might want to log or display an error message
          console.error(`Failed to accept bid for order ${orderBid.order_number}. Error:`, error);
          triggerNotificationWithMessage(`${t('somethingWentWrong')}. ${error}`, '#D31148');
        }
    };

    const cancelBid = async (orderId: any, refReg: any) => {

      const cancelETH = [
        {
          ref: `${refReg}`,
          status: 'Cancelled',
        },
        // Add more test data as needed
      ];
      // Initialize Link
    
      try {
        // Call the cancelOffer method with the orderId as input
        const linkIframeOptions: ConfigurableIframeOptions = isMobile()
  ? null // On mobile, fallback to pop-up
  : { className: 'my-link-iframe' };
  
  
        let link = new Link('https://link.sandbox.x.immutable.com', linkIframeOptions);
        await link.cancelOffer({
          orderId: orderId,
        });

         // If execution reaches this point, it means acceptOffer did not throw an error
         console.log(`Bid cancel for order ${orderId} succeeded`);
    
         // Call saveSalesData to save sales data
         saveSalesData(cancelETH);
         console.log('cancel_ETH_OFF', cancelETH);
         setIsOfferSectionVisible(false);
         const namediv = 'saleModal'
         setDivIdToReloadMajor(namediv);
         triggerNotificationWithMessage(`${t('offerSuccessfullyRemoved')}`, '#20AC61')
         handleRefresh();
         
         
    
      } catch (error) {
        // Catch and print out the error
        console.error('Error canceling bid:', error);
         triggerNotificationWithMessage(`${t('somethingWentWrong')}. ${error}`,'#D31148')
      }
    };

    const CancelBidCash = async (clientSecretID: any, refReg: any) => {

      const cancelFIA = [
        {
          ref: `${refReg}`,
          status: 'Cancelled',
        },
        // Add more test data as needed
      ];

      const paymentIntentId = clientSecretID.split('_secret')[0];
      
      try {
        const serverURL = `${linkUrl}/wp-json/custom/v1`;
      

      fetch(`${serverURL}/cancel-payment-intent`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          id: paymentIntentId, // Include the id property
        }),
      })
        .then((res) => res.json())
        .then((data) => '');

        saveSalesData(cancelFIA);
        const namediv = 'saleModal'
        setDivIdToReloadMajor(namediv);
        triggerNotificationWithMessage(`${t('offerSuccessfullyRemoved')}`, '#20AC61')
        handleRefresh();
        
      } catch (error) {
        console.error('Error canceling bidcash:', error);
        triggerNotificationWithMessage(`${t('somethingWentWrong')}. ${error}`,'#D31148')
      }

    };

    const acceptBidCash = async (bidOrder:any) => {

      const currentDate = new Date();
      const currentDateTimeString = currentDate.toISOString();

      const completeSALE = [
        {
          ref: `${bidOrder?.order_number}_SALE`,
          date_time_payment: currentDateTimeString,
          status: 'Completed',
          winner: `${bidOrder?.walletuser}`
        },
    ];

      const captureFIA = [
        {
          ref: `${bidOrder?.refSales}`,
          date_time_payment: currentDateTimeString,
          status: 'Completed',
          winner: `${bidOrder?.walletuser}`
        },
      ];

    
      // Initialize Link with your API URL
      const linkIframeOptions: ConfigurableIframeOptions = isMobile()
  ? null // On mobile, fallback to pop-up
  : { className: 'my-link-iframe' };


      let link = new Link('https://link.sandbox.x.immutable.com', linkIframeOptions);
  
      
      try {
        // Call the transfer method

       
        const result = await link.transfer([
          {
            type: ERC721TokenType.ERC721,  // Use the literal string 'ERC721'
            toAddress: bidOrder?.walletuser,
            tokenId: bidOrder?.token_id,
            tokenAddress: bidOrder?.token_address,
          },
        ]);
    
        // Extract the status from the result
        const { status } = result.result[0];
    
        // Perform actions based on the status
        if (status === 'success') {
          
    
          // Continue with the rest of the logic
          const paymentIntentId = bidOrder.client_secret_stripe.split('_secret')[0];
         
          
          try {
            const serverURL = `${linkUrl}/wp-json/custom/v1`;
    
            // Capture payment intent
            fetch(`${serverURL}/capture-payment-intent`, {
              method: 'POST',
              headers: { 'Content-Type': 'application/json' },
              body: JSON.stringify({
                id: paymentIntentId,
              }),
            })
              .then((res) => res.json())
              .then((data) => '');

            
    
            // Save sales data
            saveSalesData(captureFIA);
            saveSalesData(completeSALE);
            cleanOrders(bidOrder?.order_number);




            triggerNotificationWithMessage(`${t('congrats')}. ${t('saleSuccessfullyCompleted')}`, '#20AC61');
            // Additional actions
            const namediv = 'saleModal';
            setDivIdToReloadMajor(namediv);
            setTimeout(onClose, 2500);
            handleRefresh();
    
            
          } catch (error) {
            // Catch and print out the error
            console.error('Error canceling bidcash:', error);
            triggerNotificationWithMessage(`${t('somethingWentWrong')}. ${error}`,'#D31148')
          }
        }
      } catch (error) {
        // Catch and print out the error
        console.error('Error transferring tokens:', error);
        triggerNotificationWithMessage(`${t('somethingWentWrong')}. ${error}`,'#D31148')
      }
    };

    const acceptBuyCash = async (bidOrder:any) => {

      const currentDate = new Date();
      const currentDateTimeString = currentDate.toISOString();

      const completeSALE = [
        {
          ref: `${bidOrder?.order_number}_SALE`,
          date_time_payment: currentDateTimeString,
          status: 'Completed',
        },
    ];

      const captureFIA = [
        {
          ref: `${bidOrder?.refSales}`,
          date_time_payment: currentDateTimeString,
          status: 'Completed',
        },
      ];

      
    
      // Initialize Link with your API URL
      const linkIframeOptions: ConfigurableIframeOptions = isMobile()
  ? null // On mobile, fallback to pop-up
  : { className: 'my-link-iframe' };


      let link = new Link('https://link.sandbox.x.immutable.com', linkIframeOptions);
  
      
      try {
        // Call the transfer method

        console.log('transfer', bidOrder);
        const result = await link.transfer([
          {
            type: ERC721TokenType.ERC721,  // Use the literal string 'ERC721'
            toAddress: bidOrder?.walletuser,
            tokenId: bidOrder?.token_id,
            tokenAddress: bidOrder?.token_address,
          },
        ]);
    
        // Extract the status from the result
        const { status } = result.result[0];
    
        // Perform actions based on the status
        if (status === 'success') {
    
          // Continue with the rest of the logic
          const paymentIntentId = bidOrder.client_secret_stripe.split('_secret')[0];
         
          
          try {
            const serverURL = `${linkUrl}/wp-json/custom/v1`;
          
    
            // Capture payment intent
            fetch(`${serverURL}/capture-payment-intent`, {
              method: 'POST',
              headers: { 'Content-Type': 'application/json' },
              body: JSON.stringify({
                id: paymentIntentId,
              }),
            })
              .then((res) => res.json())
              .then((data) => '');
    
            // Save sales data
            saveSalesData(captureFIA);
            saveSalesData(completeSALE);
            cleanOrders(bidOrder?.order_number);
            triggerNotificationWithMessage(`${t('congrats')}. ${t('saleSuccessfullyCompleted')}`, '#20AC61');
          
            // Additional actions
            const namediv = 'saleModal';
            setDivIdToReloadMajor(namediv);
            setTimeout(onClose, 2500);
            handleRefresh();
            
          } catch (error) {
            // Catch and print out the error
            console.error('Error canceling bidcash:', error);
            triggerNotificationWithMessage(`${t('somethingWentWrong')}. ${error}`,'#D31148')
          }
        }
      } catch (error) {
        // Catch and print out the error
        console.error('Error transferring tokens:', error);
        triggerNotificationWithMessage(`${t('somethingWentWrong')}. ${error}`,'#D31148')
      }
    };

  const calculateMaxBid = (orderList: any, exchangeRates: any, userCurrency: any) => {
  let maxBid = 0;
  let bidValue =0;
  orderList.forEach((offer:any) => {
    if ((offer && offer.status === 'Pending') || (offer && offer.status === 'toTransfer')) {
 

      if (offer.eth_or_cashbid.toString() === 'ETH') {
        if (userCurrency === 'USD') {
          bidValue = parseFloat(offer.eth_value) * exchangeRates.USD;
      
        } else if (userCurrency === 'EUR') {
          bidValue = parseFloat(offer.eth_value) * exchangeRates.EUR;
     
        }
      } else if (offer.eth_or_cashbid.toString() === 'FIA') {
        bidValue = (userCurrency === 'USD') ? parseFloat(offer.dollar_value) : parseFloat(offer.euro_value);
        
      }

      if (bidValue && bidValue > maxBid) {
        maxBid = bidValue;
      }
    }
  });
  
  setMaximumBid(maxBid);
    };

  const fetchDesiredSale = async (order: any, currency: string) => {
  //const refSALE = `${order.order_id}_SALE`;

  // Fetch dollar_value
  //const dollarRefResponse = await fetch(`https://peaxel.me/wp-json/custom/v1/sales-request-registry?columnA=ref&valueA=${refSALE}&columnB=dollar_value`);
  //const dollarRefData = await dollarRefResponse.json();
  //const dollarValue = parseFloat(dollarRefData.toString());
  const dollarValue = parseFloat(order.talentCard.desired_dollar);

  // Fetch euro_value
  //const euroRefResponse = await fetch(`https://peaxel.me/wp-json/custom/v1/sales-request-registry?columnA=ref&valueA=${refSALE}&columnB=euro_value`);
  //const euroRefData = await euroRefResponse.json();
  //const euroValue = parseFloat(euroRefData.toString());
  const euroValue = parseFloat(order.talentCard.desired_euro);

  // Determine the desired value based on the currency
  const desiredValue = currency === 'USD' ? dollarValue : euroValue;

  const desiredValueWithTaxes = desiredValue * 1.05;

  // Set the desiredSale state
  setDesiredSale(parseFloat(desiredValueWithTaxes.toFixed(2)));
    };


// Assuming you have an order, orderList, and currency available
useEffect(() => {
  // Call fetchDesiredSale when order, orderList, or currency changes
  fetchDesiredSale(order, currency);
  
 // const fetchData = async () => {
    setOrderTalentCard(order.talentCard);
    console.log('orderSalesData', order.talentCard);
  //};

 // fetchData();
  
}, [order, orderList, currency]);



const handleDisplayAndBuyOut = () => {
  let inbetween = 0;
  let showMe = true;
  let buybuyout = 0;
  let buybuyoutETH = 0;

  if (maximumBid > 0) {
    inbetween = maximumBid;

    // Now you can safely use desiredSale
  
    if (inbetween * 1.5 > desiredSale) {
      showMe = true;
      
      buybuyout = inbetween * 1.5;

      if (exchangeRates) { 
        if (currency === 'USD') {  // Fix: Use '===' for comparison
          buybuyoutETH = buybuyout / exchangeRates.USD;
        } else {
          buybuyoutETH = buybuyout / exchangeRates.EUR;
        }
      }

      console.log("valueBuyOut", buyOutValue);
    } else {
      showMe = false;
      console.log("SHOWBUYOUT false");
      buybuyout = 0;
      console.log("valueBuyOut", buyOutValue);
    }

  } else {
   
    inbetween = desiredSale;
    // Now you can safely use desiredSale

    console.log("nextAmount", nextAmount);

    if (inbetween * 1.5 > desiredSale) {
      showMe = true;
      buybuyout = inbetween * 1.5;

      if (exchangeRates) { 
        if (currency === 'USD') {  // Fix: Use '===' for comparison
          buybuyoutETH = buybuyout / exchangeRates.USD;
        } else {
          buybuyoutETH = buybuyout / exchangeRates.EUR;
        }
      }

    } else {
      showMe = false;
      console.log("SHOWBUYOUT false");
      buybuyout = 0;
      buybuyoutETH = 0;
      
    }
  }

  setDisplayBid(inbetween);
  setShowBuyOut(showMe);
  setBuyOutValue(buybuyout);
  setBuyOutValueETH(buybuyoutETH);
  setNextAmount(inbetween + 1);

};

useEffect(() => {
  handleDisplayAndBuyOut();
}, [maximumBid, desiredSale, divIdToReloadMajor, orderList, showBuyOut, buyOutValue]); // Add maximumBid and desiredSale as dependencies



async function fetchOrderList(order: Order) {
  try {
    const fetchAdditionalInfo = async (offerNumber: string) => {
      const fetchData = async (url: string) => {
        const response = await fetch(url);

        if (response.ok) {
          return await response.json();
        } else {
          console.error(`Error fetching data from ${url}:`, response.status);
          return null;
        }
      };

      const [ethValueData, buyerResponseJson, dateTimePaymentData, clientSecretStripeData, euroValueData, dollarValueData, ethOrCashData, refSalesData, statusData, tokenAddressData, tokenIdData, orderData, currencyData, ownerData, winnerData] = await Promise.all([
        fetchData(`/wp-json/custom/v1/sales-request-registry?columnA=offer_number&valueA=${offerNumber}&columnB=eth_value`),
        fetchData(`/wp-json/custom/v1/sales-request-registry?columnA=offer_number&valueA=${offerNumber}&columnB=buyer`),
        fetchData(`/wp-json/custom/v1/sales-request-registry?columnA=offer_number&valueA=${offerNumber}&columnB=date_time_payment`),
        fetchData(`/wp-json/custom/v1/sales-request-registry?columnA=offer_number&valueA=${offerNumber}&columnB=client_secret_stripe`),
        fetchData(`/wp-json/custom/v1/sales-request-registry?columnA=offer_number&valueA=${offerNumber}&columnB=euro_value`),
        fetchData(`/wp-json/custom/v1/sales-request-registry?columnA=offer_number&valueA=${offerNumber}&columnB=dollar_value`),
        fetchData(`/wp-json/custom/v1/sales-request-registry?columnA=offer_number&valueA=${offerNumber}&columnB=eth_or_cash`),
        fetchData(`/wp-json/custom/v1/sales-request-registry?columnA=offer_number&valueA=${offerNumber}&columnB=ref`),
        fetchData(`/wp-json/custom/v1/sales-request-registry?columnA=offer_number&valueA=${offerNumber}&columnB=status`),
        fetchData(`/wp-json/custom/v1/sales-request-registry?columnA=offer_number&valueA=${offerNumber}&columnB=token_address`),
        fetchData(`/wp-json/custom/v1/sales-request-registry?columnA=offer_number&valueA=${offerNumber}&columnB=token_id`),
        fetchData(`/wp-json/custom/v1/sales-request-registry?columnA=offer_number&valueA=${offerNumber}&columnB=order_number`),
        fetchData(`/wp-json/custom/v1/sales-request-registry?columnA=offer_number&valueA=${offerNumber}&columnB=currency`),
        fetchData(`/wp-json/custom/v1/sales-request-registry?columnA=offer_number&valueA=${offerNumber}&columnB=owner`),
        fetchData(`/wp-json/custom/v1/sales-request-registry?columnA=offer_number&valueA=${offerNumber}&columnB=winner`),
      ]);

      if (ethValueData !== null && buyerResponseJson !== null && currencyData !== null && dateTimePaymentData !== null &&
        clientSecretStripeData !== null && euroValueData !== null && dollarValueData !== null && ethOrCashData !== null && refSalesData !== null  && tokenAddressData !== null && tokenIdData !== null && orderData !== null && ownerData !== null && winnerData !== null) {

        const buyerDataUser = await fetchUserNamesByMetadata(buyerResponseJson.toString());

        const buyerData = buyerDataUser.length > 0 ? buyerDataUser[0] : 'immortale';



        return {
          offer_number: offerNumber,
          order_number: orderData.toString(),
          eth_value: ethValueData,
          buyer: buyerData,
          date_time_payment: dateTimePaymentData,
          client_secret_stripe: clientSecretStripeData.toString(),
          euro_value: euroValueData,
          dollar_value: dollarValueData,
          eth_or_cashbid: ethOrCashData,
          refSales:refSalesData,
          walletuser:buyerResponseJson.toString(),
          status:statusData.toString(),
          token_address:tokenAddressData.toString(),
          token_id:tokenIdData.toString(),
          currency:currencyData.toString(),
          owner:ownerData.toString(),
          winner:winnerData.toString(),
        };
      } else {
        console.error('Error fetching additional information.');
        return null;
      }
    };

    const response = await fetch(`${linkUrl}/wp-json/custom/v1/sales-request-registry?columnA=order_number&valueA=${order.order_id}&columnB=offer_number`);

    if (response.ok) {
      const data: (string | null)[] = await response.json();

      const additionalInfoPromises = data
        .filter((offerNumber: string | null): offerNumber is string => offerNumber !== null)
        .map(fetchAdditionalInfo);

      const additionalInfoResults = await Promise.all(additionalInfoPromises);

      const validResults = additionalInfoResults
  .filter((result) => result !== null)
  .sort((a, b) => {
    // Convert dollar_value to numbers for comparison
    const valueA = parseFloat(a?.dollar_value);
    const valueB = parseFloat(b?.dollar_value);

    // Sort in descending order
    return valueB - valueA;
  });

console.log('Final Result:', validResults);

return validResults;
    } else {
      console.error('Error fetching order list:', response.status);
      return null;
    }
  } catch (error) {
    console.error('Error fetching order list:', error);
    return null;
  }
}


    async function fetchUserNamesByMetadata(walletAddress: string): Promise<string[]> {
      try {
        const response = await axios.get(`${linkUrl}/wp-json/custom/v1/get_user_by_metadata?value=${walletAddress}`, {
          headers: {
            'Content-Type': 'application/json',
          },
        });
        return response.data; // Assuming the response directly contains user names
      } catch (error) {
        console.error('Error fetching user names:', error);
        return [];
      }
    }

    useEffect(() => {
      // Call fetchOrderList and update the state with the result
      const fetchData = async () => {
        const result = await fetchOrderList(order);
        if (result !== null) {
          setOrderList(result);
          calculateMaxBid(result, exchangeRates, currency);
        }
      };
  
      fetchData();
      

    }, [order, divIdToReloadMajor]);
    

    useEffect(() => {
      if (divIdToReload) {
        const timeoutId = setTimeout(() => {
          
          setDivIdToReload(null);
        }, 2500);
  
        return () => clearTimeout(timeoutId);
      }
    }, [divIdToReload]);

    useEffect(() => {
      if (divIdToReloadMajor) {
        const timeoutId = setTimeout(() => {
         
          setDivIdToReloadMajor(null);
        }, 1000);
  
        return () => clearTimeout(timeoutId);
      }
    }, [divIdToReloadMajor]);


  useEffect(() => {
        async function fetchOrderInfo() {
          try {
            const response = await fetch(`https://api.sandbox.x.immutable.com/v1/assets/${order.sell.data.token_address}/${order.sell.data.token_id}`);
            const data = await response.json();
            setOrderInfo(data);
           
          } catch (error) {
            console.error('Error fetching order info:', error);
          }
        }
    
        fetchOrderInfo();
      
      }, [order, divIdToReloadMajor]); // Fetch order info when the order prop changes

  useEffect(() => {
    // Add or remove the "modal-open" class on the body element
    document.body.classList.add('modal-open');

    // Clean up by removing the class when the modal is closed
    return () => {
      document.body.classList.remove('modal-open');
  
    };
    
  }, []);

  useEffect(() => {
    
    let rodri = nextAmount.toFixed(2);
    // Set the calculated default offer amount in the state
    setDefaultOfferAmount(rodri.toString());
    // Set the initial value of the input field to the default offer amount
    setOfferAmount(rodri.toString());
  }, [order, exchangeRates, currency, divIdToReloadMajor, nextAmount]);

  useEffect(() => {
    if (exchangeRates) {
      const rate = currency === 'USD' ? exchangeRates.USD : exchangeRates.EUR;
      const calculatedDefaultAmountInETH = (
        (parseFloat(defaultOfferAmount) / rate).toFixed(6)
      );
      setOfferAmountInETH(parseFloat(calculatedDefaultAmountInETH)); // Parse as a number
    } else {
      setOfferAmountInETH(null);
    }
  }, [defaultOfferAmount, exchangeRates, currency, divIdToReloadMajor, nextAmount]);


 const CountdownTimer: React.FC<{ targetDate: Date }> = ({ targetDate }) => {
    const calculateTimeLeft = () => {
      const now = new Date().getTime(); // Get the current timestamp in milliseconds
const targetTimestamp = targetDate.getTime(); // Get the targetDate timestamp in milliseconds
const difference = targetTimestamp - now; // Calculate the difference in milliseconds
  
     
  
      const days = Math.floor(difference / (1000 * 60 * 60 * 24));
      const hours = Math.floor((difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((difference % (1000 * 60)) / 1000);
  
      return {
        days,
        hours,
        minutes,
        seconds,
      };
    };
  
    const [timeLeft, setTimeLeft] = useState(calculateTimeLeft()); 
  
    useEffect(() => {
      const timer = setInterval(() => {
        setTimeLeft(calculateTimeLeft());
      }, 1000);
  
      return () => {
        clearInterval(timer);
      };
    }, []); 
  
    const { days, hours, minutes, seconds } = timeLeft; 

    return (
      <span>
        {days > 0 && <span>{days}d&nbsp;</span>}
        {hours > 0 && <span>{hours}h&nbsp;</span>}
        {minutes > 0 && <span>{minutes}m&nbsp;</span>}
        {seconds > 0 && <span>{seconds}s left&nbsp;</span>}
        {days === 0 && hours === 0 && minutes === 0 && seconds === 0 && <span>Time's up!</span>}
      </span>
    );
  
  }


  const convertOrderToAsset = (order: Order): Asset => {
    return {
      collection: {
        icon_url: '',
        name: '',
      },
      created_at: '',
      description: order.sell.data.properties.name,
      id: '',
      image_url: order.sell.data.properties.image_url,
      metadata: {
        country: '',
        description: '',
        field: '',
        first_name: '',
        image_url: order.sell.data.properties.image_url,
        name: '',
        reward_per_token: '',
        token_number: '',
        total_tokens: '',
        total_tokens_first_year: '',
        total_tokens_forth_year: '',
        total_tokens_second_year: '',
        total_tokens_third_year: '',
        vote_per_token: '',
      },
      name: order.sell.data.properties.name,
      status: '',
      token_address: order.sell.data.token_address,
      token_id: order.sell.data.token_id,
      updated_at: '',
      uri: null,
      user: order.user,
      talentCard: order.talentCard,
    };
  };
  

  
  const formatISODate = (isoDate: any) => {
    const date = new Date(isoDate);
    const now = new Date();
  
    // Check if the year is the current year
    const isCurrentYear = date.getFullYear() === now.getFullYear();
  
    const year = isCurrentYear ? '' : date.getFullYear();
    const month = date.toLocaleString('default', { month: 'short' });
    const day = date.getDate();
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const amOrPm = hours >= 12 ? 'PM' : 'AM';
  
    // Convert hours from 24-hour format to 12-hour format
    const formattedHours = hours % 12 || 12;
  
    // Combine the formatted parts into the desired format
    return `${year} ${month} ${day}, ${formattedHours}:${minutes.toString().padStart(2, '0')} ${amOrPm}`;
  };

 

  

  const description = `${order.sell.data.properties.name} ${orderInfo?.metadata?.token_number} / ${orderInfo?.metadata?.total_tokens}`;

  const customerWho = localStorage.getItem('walletAddress')?.toString() || '';

  const customerID = localStorage.getItem('walletAddress')?.toString() || '';

  //const totalAmount = useMemo(() => {
   // if (exchangeRates) {
   //   return order.bidmax !== undefined && order.bidmax > parseFloat(order.buy.data.quantity_with_fees)
   //     ? (
   //         hexToDecimal(order.bidmax.toString(), order.buy.data.decimals) *
    //        (currency === 'USD' ? exchangeRates.USD : exchangeRates.EUR)
   //       ).toFixed(2)
    //    : (
   //         hexToDecimal(order.buy.data.quantity_with_fees, order.buy.data.decimals) *
   //         (currency === 'USD' ? exchangeRates.USD : exchangeRates.EUR)
   //       ).toFixed(2);
   // } else {
   //   return 'Loading...';
  //  }
  //}, [exchangeRates, order.bidmax, order.buy.data.quantity_with_fees, order.buy.data.decimals, currency]);
  

  const totalAmountETH = useMemo(() => {
    if (exchangeRates) {
      return buyOutValue !== undefined && currency === 'USD'
        ? (buyOutValue / exchangeRates.USD).toFixed(10)
        : (buyOutValue / exchangeRates.EUR).toFixed(10);
    } else {
      return '';
    }
  }, [exchangeRates, buyOutValue, currency]);

  let dollarAmount;
  let euroAmount;

  if (exchangeRates) {
    dollarAmount =
      buyOutValue!== undefined && currency === 'USD'
        ? (
            buyOutValue
          ).toFixed(2)
        : (
          buyOutValue / exchangeRates.EUR * exchangeRates.USD 
          ).toFixed(2);
  } else {
    dollarAmount = ''; // or render some loading indicator
  }

  if (exchangeRates) {
    euroAmount =
    buyOutValue!== undefined && currency === 'EUR'
    ? (
        buyOutValue 
      ).toFixed(2)
    : (
      buyOutValue / exchangeRates.USD * exchangeRates.EUR 
      ).toFixed(2);
  } else {
    euroAmount = ''; // or render some loading indicator
  }

  const owner= order.user;
  const theAmount = parseFloat((parseFloat(offerAmount) * 100).toFixed(0));
  const theAmountCB = parseFloat((parseFloat(buyOutValue.toString()) * 100).toFixed(0));

  let theAmountUSD, theAmountEUR, theAmountETH;

  if (exchangeRates) {

    if (currency === 'USD') {
      theAmountUSD = theAmount / 100;
      theAmountEUR = (theAmount / exchangeRates.USD) * exchangeRates.EUR / 100 ;
      theAmountETH = (theAmount / exchangeRates.USD) / 100;
    } 
    if (currency === 'EUR') {
      theAmountEUR = theAmount / 100 ;
      theAmountUSD = (theAmount / exchangeRates.EUR) * exchangeRates.USD / 100 ;
      theAmountETH = (theAmount / exchangeRates.EUR) / 100 ;
    } 

  }

  const currentDate = new Date();
  const currentDateTimeString = currentDate.toISOString();

  const registerDataETH_BUY = [
    {
      ref: `${order.order_id}_ETH_BUY_${customerID}`,
      date_time_payment: currentDateTimeString,
      date_time_validation: '',
      sale_or_offer: 'Buy',
      order_number: order.order_id,
      offer_number: `${order.order_id}_BUY`,
      eth_value: totalAmountETH,
      dollar_value: dollarAmount,
      euro_value: euroAmount,
      currency: currency,
      eth_or_cash: 'ETH',
      client_secret_stripe: '',
      owner: owner,
      buyer: customerWho,
      token_address: order.sell.data.token_address,
      token_id: order.sell.data.token_id,
      token_name: order.sell.data.properties.name,
      status: 'toTransfer',
      winner: '-',
    },
    // Add more test data as needed
  ];

  const registerDataFIA_BUY = [
    {
      ref: `${order.order_id}_FIA_BUY_${customerID}`,
      date_time_payment: currentDateTimeString,
      date_time_validation: currentDateTimeString,
      sale_or_offer: 'Buy',
      order_number: order.order_id,
      offer_number: `${order.order_id}_BUY`,
      eth_value: totalAmountETH,
      dollar_value: dollarAmount,
      euro_value: euroAmount,
      currency: currency,
      eth_or_cash: 'FIA',
      client_secret_stripe: '',
      owner: owner,
      buyer: customerWho,
      token_address: order.sell.data.token_address,
      token_id: order.sell.data.token_id,
      token_name: order.sell.data.properties.name,
      status: 'toTransfer',
      winner: '-',
    },
    // Add more test data as needed
  ];
  
  const registerDataETH_OFF = [
    {
      ref: `${order.order_id}_ETH_OFF_${customerID}`,
      date_time_payment: currentDateTimeString,
      date_time_validation: '',
      sale_or_offer: 'Offer',
      order_number: order.order_id,
      offer_number: '',
      eth_value: theAmountETH,
      dollar_value: theAmountUSD,
      euro_value: theAmountEUR,
      currency: currency,
      eth_or_cash: 'ETH',
      client_secret_stripe: '',
      owner: owner,
      buyer: customerWho,
      token_address: order.sell.data.token_address,
      token_id: order.sell.data.token_id,
      token_name: order.sell.data.properties.name,
      status: 'Pending',
      winner: '-',
    },
    // Add more test data as needed
  ];

  const registerDataFIA_OFF = [
    {
      ref: `${order.order_id}_FIA_OFF_${customerID}`,
      date_time_payment: currentDateTimeString,
      date_time_validation: '',
      sale_or_offer: 'Offer',
      order_number: order.order_id,
      offer_number: `${order.order_id}_${customerID}`,
      eth_value: theAmountETH,
      dollar_value: theAmountUSD,
      euro_value: theAmountEUR,
      currency: currency,
      eth_or_cash: 'FIA',
      client_secret_stripe: '',
      owner: owner,
      buyer: customerWho,
      token_address: order.sell.data.token_address,
      token_id: order.sell.data.token_id,
      token_name: order.sell.data.properties.name,
      status: 'Pending',
      winner: '-',
    },
    // Add more test data as needed
  ];

  

    return (
      <div className="App">
      <div className="order-modal" key={divIdToReloadMajor}>
        <div className="modal-content" key={divIdToReloadMajor}>
        <div className="product-details " key={divIdToReloadMajor}>
            
            {isMobileConst === false && (  
            <div className="product-image-column"> 
            <img width="95%" src={tokenImage || undefined} alt={order.sell.data.properties.name} />
            </div >
            )}  
            
            <div className="product-info-column" key={divIdToReloadMajor} id="saleModal">
            {isMobileConst === true && (   
            <div className="product-image-column"> 
            <img width="95%" src={tokenImage || undefined} alt={order.sell.data.properties.name} />
            </div >
            )}  
              <div className="product-name">

              <span
    onClick={() => {
      const asset = convertOrderToAsset(order);
      openPageViewModal(asset, ''); // Open the page view modal
      onClose(); // Close the asset modal
    }}
    style={{ cursor: 'pointer'}}
  >
    <i className="fa-solid fa-id-badge" style={{ color: '#93003f', fontSize: '75%', marginRight: '2px' }}></i>&nbsp;{order.sell.data.properties.name}
  </span>
    
    
    {isMobileConst===false &&(<span>&nbsp;&nbsp;&nbsp;&nbsp;</span>)}

    <span className="card-number">{orderInfo?.metadata?.token_number} / {orderInfo?.metadata?.total_tokens}</span></div>
              
              <div className="modal-div-head">
              <div className="head-left">
              <div className="product-price">  
{currency === 'USD' ? (
            <>
              <span>$</span>
            </>
          ) : (
            <>
              <span></span>
            </>
          )}
{displayBid !== null ? displayBid.toFixed(2) : "Loading..."}
{currency === 'EUR' ? (
            <>
              <span>&#8364;</span>
            </>
          ) : (
            <>
              <span></span>
            </>
          )}
</div>

  <div className="bidscount">
              <h3 className="info2">
                
              {t('includesFees')}&nbsp;

<CustomTooltipLazy
content={
<div style={{ zIndex: '10000000000' }}>
<p><strong>{t('tradingFees')}</strong></p>
<p style={{ opacity: 0.7 }}><span style={{ color: '#FFA500' }}><strong>{t('feesIncluded')}</strong></span> {t('feesIncludedDescription')}. 
</p>
<ul style={{ opacity: 0.7 }}>
<li>{t('2')}% - {t('protocolsFee')}</li>
<li>{t('1')}% - {t('marketplaceFee')}</li>
<li>{t('1')}% - {t('royaltiesToTalents')}</li>
<li>{t('1')}% - {t('rewards')}</li>
</ul>
<p style={{ opacity: 0.7 }}>{t('feeProportionsMayVary')}</p>
</div>
}
>
<button className="button_tooltip"><i className="fa-solid fa-circle-info"></i></button>
</CustomTooltipLazy> &nbsp;•&nbsp;

              {orderTalentCard?.offers_count && parseInt(orderTalentCard?.offers_count) > 0 && (
    <span>
      {orderTalentCard?.offers_count}&nbsp;
      
      {parseInt(orderTalentCard?.offers_count) === 1 
        ? t('offer')
        : t('offers')}
      
      
      &nbsp;•&nbsp;
    </span>
  )}
              {order.expiration_timestamp
      ? formatExpirationTimestamp(order.expiration_timestamp)
      : 'N/A'}
      <span style={{ fontSize: '16px', }}>&nbsp;•&nbsp;</span>
      
              </h3>
              </div>
              </div>


{walletAddress !== order.user && (
<div className="CallActions2" >

{isOfferButtonVisible && (
  <button
    className="buy-button2"
    onClick={handleMakeOfferClick} 
    style={{ zIndex: 4000 }}
  >
    {isMakingOffer ? t('OfferPending') : t('makeAnOffer')}
  </button>
)}

{walletAddress === order.user && (

<button onClick={(e) => {
      e.preventDefault(); // Prevent the default link behavior
      cancelOrder(order.order_id);
    }} className="buy-button2" >
                      {t('removeFromSale')}
                    </button>
                
)}


{((showBuyOut) && (isSubscriber === true) && isBuyOutButtonVisible && (order.user != '0xbdc8186faa4c50061948edfdfc4a5b534c42a45d') ) && (

  <button
    className={isPaymentSectionVisible ? 'buy-button2' : 'bid-button2'}
    onClick={togglePaymentSection}
  >

  Buy Out | {currency === 'USD' ? (
            <>
              <span>$</span>
            </>
          ) : (
            <>
              <span></span>
            </>
          )}
          {buyOutValue.toFixed(2)}
          {currency === 'EUR' ? (
            <>
              <span>&#8364;</span>
            </>
          ) : (
            <>
              <span></span>
            </>
          )}
  </button>

)}

</div>
) }

{walletAddress === order.user && (
<div className="CallActions2" >

<button onClick={(e) => {
      e.preventDefault(); // Prevent the default link behavior
      cancelOrder(order.order_id);
    }} className="buy-button2" >
                      Remove from Sale
                    </button>             

</div>
) }

</div>

<div>
{notification && (
  <Suspense fallback={<div>{t('loading')}</div>}>
        <NotificationLazy
          message={notification.message}
          onClose={closeNotification}
          backgroundColor={notification.backgroundColor}
        />
        </Suspense>
      )}
</div>


<div className="modal-div" id="Offer" style={{ display: 'none' }}>
</div>


{isMakingOffer ? (
    <p>{t('offerPlacedSuccessfully')}</p>
  ) : (
<div className="modal-div" id="Payment-buy">
    <div className="make-offer" >
 
      {isOfferSectionVisible && (
        <div>
        <div className="sell-make-offer-tab" id="zDindex2" >
        <span className="order-ETH">{t('enterTheProposedAmount')}</span>
          <span className="offer-input">
            <input
              type="text"
              placeholder={`${t('EnterOfferAmount')} (${currency === 'USD' ? '$' : '€'})`}
              value={`${currency === 'USD' ? '$' : '€'}${offerAmount}`}
              onChange={handleOfferInputChange}
              className="offer-input"
              readOnly={false}
              ref={offerInputRef}
              
              onInput={(e) => {
                // Allow only numeric input and a single dot for decimals
                e.currentTarget.value = e.currentTarget.value.replace(/[^0-9.]/g, '');
              }}
            />
            </span>
            <span className="order-ETH">
              <span className="order-ETH-approx">~</span>&nbsp;{offerAmountInETH !== null ? offerAmountInETH : 'Error'}&nbsp;ETH
            </span>
<br/>
<span className="order-ETH" ><span className="order-ETH-approx">{t('includesFees')}&nbsp;


<CustomTooltipLazy
content={
<div style={{ zIndex: '10000000000' }}>
<p><strong>Trading Fees</strong></p>
<p style={{ opacity: 0.7 }}><span style={{ color: '#FFA500' }}><strong>Fees are included</strong></span> in the asking price and your offers. 
</p>
<ul style={{ opacity: 0.7 }}>
<li>2% - {t('protocolsFee')}</li>
<li>1% - {t('marketplaceFee')}</li>
<li>1% - {t('royaltiesToTalents')}</li>
<li>1% - {t('rewards')}</li>
</ul>
<p style={{ opacity: 0.7 }}>* {t('feeProportionsMayVary')}</p>
</div>
}
>
<button className="button_tooltip"><i className="fa-solid fa-circle-info"></i></button>
</CustomTooltipLazy>

</span></span>
          
        </div>
        <span className='notice_orders'>{t('offersValidForOneWeek')}</span>

        
        <div className="modal-div" id="PaymentBid">
        <div className="make-offer">

{ (acceptedETHPayment && isSubscriber === true) && (
<div className="sell-make-offer-tab" id="EtherumBid" >
        <span className="PaymentLabel"> <i className="fa-brands fa-ethereum"></i>&nbsp;<span>{t('offer')}</span> {theAmount/100} {currency} <span>{t('withEtherumWallet')}</span></span>
        {((locationPath.includes("competition") || locationPath.includes("market") || locationPath.includes("/profile") || locationPath.includes('team')) ) && (
  <button
    className="buy-button"
    onClick={() => handleMakeOffer(registerDataETH_OFF)} 
    disabled={isMakingOffer}
    style={{ opacity: isMakingOffer ? 0.3 : 1 }}
  >

    { isMakingOffer
        ? t('OfferPlacedinETH')
        : t('ProceedWithOffer')}
  </button>
)}

</div>
) }

{acceptedCardPayment && (
<div className="sell-make-offer-tab" id="CreditCardBid" key={divIdToReload} >
<span className="PaymentLabel"><i className="fa-regular fa-credit-card"></i>&nbsp;<span>{t('makeTheOfferOf')}</span> {theAmount/100} {currency} <span>{t('withCreditDebitCard')}</span></span>

<PaymentBid
  amountBI={theAmount}
  description={description}
  owner={owner}
  currency={currency}
  buyer={customerWho}
  orderPass={registerDataFIA_OFF}
/>
</div>
) }

</div>       
</div>
             
        
        </div>
      )}
    </div>

  </div>
  )}


{showBuyOut && isPaymentSectionVisible && (
<div className="modal-div" id="Payment-buy" style={{ display: 'none' }} >
<div className="make-offer" >

{(acceptedETHPayment  && isSubscriber === true) && (
<div className="sell-make-offer-tab" id="Etherum" >
        <span className="PaymentLabel"><i className="fa-brands fa-ethereum"></i>&nbsp;<span>{t('pay')}</span> {buyOutValue} {currency} <span>{t('withEthereumWallet')}</span></span>

        {((locationPath.includes("market") || locationPath.includes("/profile") || locationPath.includes('team') || locationPath.includes('competition')) && (order.user != '0xbdc8186faa4c50061948edfdfc4a5b534c42a45d') )&& (
  <button
    className="buy-button"
    onClick={() => handleMakeOfferBuyOut(registerDataETH_BUY)} 
    disabled={buyOutValueETH > pxlBalance}
    style={{ opacity: buyOutValueETH > pxlBalance ? 0.5 : 1 }}
  >
    {buyOutValueETH < pxlBalance
      ? t('completePayment')
      : t('insufficientETHFunds')}
  </button>
)}

</div>
)}


{acceptedCardPayment && (
<div className="sell-make-offer-tab" id="CreditCard" style={{ zIndex: '4000!important;' }}>
<span className="PaymentLabel"><i className="fa-regular fa-credit-card"></i>&nbsp;<span>{t('pay')}</span> {buyOutValue} {currency} <span>{t('withCreditDebitCard')}</span></span>
<Payment
  amountGE={theAmountCB}
  description={description}
  owner={owner}
  currency={currency}
  buyer={customerWho}
  orderPass={registerDataFIA_BUY}
/>
</div>
)}

</div>       
</div>
)}


{orderList.filter(bidOrder => bidOrder?.status === "toTransfer").length  > 0 && (
  <div className="modal-div" >
    
    <h2>
      {t('1BuyOut')}
    </h2>

  <div className="info-tab-inside" >
    <div style={{ width: '100%'}}>
    <div style={{ width: '100%'}}>
  {orderList.map((bidOrder) => (
bidOrder?.status === "toTransfer" && (
    <div key={bidOrder?.offer_number} className="info-tab-bid">
      <div className="infobid1">
        <div className="infobidlogo">{Array.isArray(bidOrder?.buyer) ? bidOrder?.buyer[0]?.charAt(0) : bidOrder?.buyer?.charAt(0)}</div>
        <div className="infobidentry">
        <span className="infobidname">
  {bidOrder?.buyer}&nbsp;&nbsp;
  <span style={{ fontSize: '85%', opacity: '60%' }}>
  {bidOrder?.eth_or_cashbid && bidOrder?.eth_or_cashbid.includes('FIA') ? (
    <i className="fa-regular fa-credit-card"></i>
  ) : (
    <i className="fa-brands fa-ethereum"></i>
  )}</span>
</span>
          <span className="infobiddate">{formatISODate(bidOrder?.date_time_payment)}&nbsp;</span>
         
        </div>
      </div>
  

{localStorage.getItem('walletAddress') === order.user && (bidOrder?.refSales?.toString()).includes('FIA_BUY') && (
  <a
    href="#"
    className="infobidaccept"
    onClick={(e) => {
      e.preventDefault(); // Prevent the default link behavior
      acceptBuyCash(bidOrder);
    }}
  >
    Finalize BuyOut
  </a>
)}

{localStorage.getItem('walletAddress') === order.user && (bidOrder?.refSales?.toString()).includes('ETH_BUY') && (
  <a
    href="#"
    className="infobidaccept"
    onClick={(e) => {
      e.preventDefault(); // Prevent the default link behavior
      acceptBid(bidOrder);
    }}
  >
    {t('finalizeBuyOut')}
  </a>
)}

      <span className="infobidprice">
  <b>
    {exchangeRates ? (
      <>
        {currency === 'USD' ? <span>$</span> : <span>&#8364;</span>}
     
        {bidOrder?.eth_or_cashbid?.includes('FIA') ? (
          // If eth_or_cash is FIA
          (currency === 'USD' ? bidOrder?.dollar_value : bidOrder?.euro_value) ?? 0
        ) : (
          // If eth_or_cash is ETH
          (currency === 'USD' ? (bidOrder?.eth_value * exchangeRates.USD).toFixed(2) : (bidOrder?.eth_value * exchangeRates.EUR).toFixed(2))?? 0
        )}{' '}
      </>
    ) : (
      'Loading...'
    )}
  </b>
  <span className="order-ETH">
  ~{((
    bidOrder?.eth_or_cashbid?.includes('FIA')
      ? (
        currency === 'USD'
          ? (bidOrder?.dollar_value && exchangeRates?.USD ? (bidOrder.dollar_value / exchangeRates.USD).toFixed(6) : 0)
          : (bidOrder?.euro_value && exchangeRates?.EUR ? (bidOrder.euro_value / exchangeRates.EUR).toFixed(6) : 0)
      )
      : (bidOrder?.eth_value || 0)
  ) ?? 0 )}{' '}ETH
</span>
</span>

    </div>
)
  ))}
</div>

    </div>
    </div>
  </div>
)}

{(orderList.filter(bidOrder => bidOrder?.status === "Pending").length  > 0) && (orderList.filter(bidOrder => bidOrder?.status === "toTransfer").length  < 1) &&(
  <div className="modal-div" key={divIdToReloadMajor}>
    {orderList.filter(bidOrder => bidOrder?.status === "Pending").length  > 0 && (
    <h2>
      {orderList.filter(bidOrder => bidOrder?.status === "Pending").length}&nbsp;
      {orderList.length === 1 
        ? t('offer')
        : t('offers')}
    </h2>
  )}
  <div className="info-tab-inside" key={divIdToReloadMajor}>
    <div style={{ width: '100%'}}>
    <div style={{ width: '100%'}}>
  {orderList.map((bidOrder) => (
bidOrder?.status === "Pending" && (
    <div key={bidOrder?.offer_number} className="info-tab-bid">
      <div className="infobid1">
        <div className="infobidlogo">{Array.isArray(bidOrder?.buyer) ? bidOrder?.buyer[0]?.charAt(0) : bidOrder?.buyer?.charAt(0)}</div>
        <div className="infobidentry">
        <span className="infobidname">
  {bidOrder?.buyer}&nbsp;&nbsp;
  <span style={{ fontSize: '85%', opacity: '60%' }}>
  {bidOrder?.eth_or_cashbid && bidOrder?.eth_or_cashbid.includes('FIA') ? (
    <i className="fa-regular fa-credit-card"></i>
  ) : (
    <i className="fa-brands fa-ethereum"></i>
  )}</span>
</span>
          <span className="infobiddate">{formatISODate(bidOrder?.date_time_payment)}&nbsp;</span>
         
        </div>
      </div>
  
      
  {localStorage.getItem('walletAddress') === bidOrder?.walletuser && bidOrder?.eth_or_cashbid?.includes('ETH') && (
  <a
    href="#"
    className="infobidcancel"
    onClick={(e) => {
      e.preventDefault(); // Prevent the default link behavior
      cancelBid(bidOrder.offer_number, bidOrder.refSales);
    }}
  >
    {t('cancelOffer')}
  </a>
)}

{localStorage.getItem('walletAddress') === bidOrder?.walletuser && bidOrder?.eth_or_cashbid?.includes('FIA') && (
  <a
    href="#"
    className="infobidcancel"
    onClick={(e) => {
      e.preventDefault(); // Prevent the default link behavior
      /*CaptureCash(bidOrder.client_secret_stripe, bidOrder.refSales);*/
      CancelBidCash(bidOrder.client_secret_stripe, bidOrder.refSales);
    }}
  >
    {t('cancelOffer')}
  </a>
)}


    {localStorage.getItem('walletAddress') === order.user && bidOrder?.eth_or_cashbid && bidOrder.eth_or_cashbid.includes('ETH') && (
  <a
    href="#"
    className="infobidaccept"
    onClick={(e) => {
      e.preventDefault(); // Prevent the default link behavior
      acceptBid(bidOrder);
    }}
  >
     {t('acceptOffer')}
  </a>
)}

{localStorage.getItem('walletAddress') === order.user && bidOrder?.eth_or_cashbid && bidOrder.eth_or_cashbid.includes('FIA') && (
  <a
    href="#"
    className="infobidaccept"
    onClick={(e) => {
      e.preventDefault(); // Prevent the default link behavior
      acceptBidCash(bidOrder);
    }}
  >
     {t('acceptOffer')}
  </a>
)}



      <span className="infobidprice">
  <b>
    {exchangeRates ? (
      <>
        {currency === 'USD' ? <span>$</span> : <span>&#8364;</span>}
     
        {bidOrder?.eth_or_cashbid?.includes('FIA') ? (
          // If eth_or_cash is FIA
          (currency === 'USD' ? bidOrder?.dollar_value : bidOrder?.euro_value) ?? 0
        ) : (
          // If eth_or_cash is ETH
          (currency === 'USD' ? (bidOrder?.eth_value * exchangeRates.USD).toFixed(2) : (bidOrder?.eth_value * exchangeRates.EUR).toFixed(2))?? 0
        )}{' '}
      </>
    ) : (
      t('loading')
    )}
  </b>
  <span className="order-ETH">
  ~{((
    bidOrder?.eth_or_cashbid?.includes('FIA')
      ? (
        currency === 'USD'
          ? (bidOrder?.dollar_value && exchangeRates?.USD ? (bidOrder.dollar_value / exchangeRates.USD).toFixed(6) : 0)
          : (bidOrder?.euro_value && exchangeRates?.EUR ? (bidOrder.euro_value / exchangeRates.EUR).toFixed(6) : 0)
      )
      : (bidOrder?.eth_value || 0)
  ) ?? 0 )}{' '}ETH
</span>
</span>

    </div>
)
  ))}
</div>

    </div>
    </div>
  </div>
)}
              <div className="modal-div">
              <h2>{t('lastSales')}</h2>
              <Suspense fallback={<div>{t('loading')}</div>}>
                <LastSalesLazy 
                talentName={order.sell.data.properties.name}
                url_picture={tokenImage || ''}
                 />
              </Suspense>
              </div>

  
              <div className="modal-div">
              <h2>{t('talents')}</h2>
              <div className="info-tab-inside">
              
              <p className="info2">
  <span
    onClick={() => {
      const asset = convertOrderToAsset(order);
      openPageViewModal(asset, ''); // Open the page view modal
      onClose(); // Close the asset modal
    }}
    style={{ cursor: 'pointer' }}
  >
    {order.sell.data.properties.name}&nbsp;<i className="fa-solid fa-square-arrow-up-right" style={{ color: '#93003f', fontSize: '75%', marginLeft: '2px' }}></i>
  </span>
</p>
              
              <h3>{t('mainActivity')}</h3>
              <p className="info2">{order.talentCard.main_activity}</p>
              <h3>{t('field')}</h3>
              <p className="info2">{orderInfo?.metadata?.field}</p>
              <h3>{t('country')}</h3>
              <p className="info2">{orderInfo?.metadata?.country}</p>
              </div>
              </div>

              <div className="modal-div">
              <h2>{t('tokenDetails')}</h2>
              <div className="info-tab-inside">
              <h3>{t('talentIndex')}</h3>
              <p className="info2">
  <a href={`${linkUrl}/profile/?${order.sell.data.properties.name}/`} rel="noopener noreferrer">
    {order.sell.data.properties.name}
  </a>
</p>
              <h3>{t('forceExperienceToken')}</h3>
              <p className="info2"> 
              <Suspense fallback={<div>{t('loading')}</div>}>
              <ExperienceDisplayLazy 
              card={order.talentCard}
              locationXP={locationPath}
              activeSection={activeSection}
        /></Suspense>
        </p>
              <h3>{t('talentTokenNumber')}</h3>
              <p className="info2">{orderInfo?.metadata?.token_number} / {orderInfo?.metadata?.total_tokens}</p>
              <h3>{t('manager')}</h3>
              <p className="info2">
  <a href={`${linkUrl}/profile/?${order.user}/`} rel="noopener noreferrer">
    {order.user}
  </a>
</p>
              <h3>{t('tokenID')}</h3>
              <p className="info2">{order.sell.data.token_id}</p>
              <h3>{t('contractAddress')}</h3>
              <p className="info2">{order.sell.data.token_address}</p>
              <h3>{t('imageURL')}</h3>
              <p className="info2"><a href={order.sell.data.properties.image_url} target="_blank" rel="noopener noreferrer">{order.sell.data.properties.image_url}</a>
              </p>
              
              </div>

            
              </div>

             
            </div>
          </div>
         
        </div>
        <button 
              className="close-button-modal1" 
              onClick={() => {onClose()}}>
             <i className="fa-regular fa-circle-xmark"></i>
            </button>
      </div>
      
      
      </div>

    );
  };
  
  export default OrderModal;

  
